import {computed, inject, Injectable, Signal, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {FeatureFlagKey} from '@shared/shared-module/config/feature-flags';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {xor} from 'lodash';
import {FeatureFlagDto, FeatureFlagRestService} from 'projects/person-data/src/app/core/api/generated/msa-person-data';
import {catchError, EMPTY, ReplaySubject, tap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ConsoleLoggingService} from '../logging/console-logging.service';

export type FeatureFlagData = FeatureFlagDto & {
  diff: boolean;
};

/**
 * This services manages all static and dynamic feature flags.
 * It allows overriding local feature flags via an endpoint.
 * If you want to profit of dynamic feature flags, then direct access
 * to the {@see environment} file is not allowed! These values are static
 * and should not be modified, neither during runtime (leads to confusion).
 */
@Injectable({providedIn: 'root'})
export class FeatureFlagService {
  private consoleLoggingService = inject(ConsoleLoggingService);
  private snackbarService = inject(SnackbarService);

  private _featureFlagMap = signal<{[name: string]: FeatureFlagData}>({});
  private _upToDate = new ReplaySubject(1);
  public featureFlags = computed(() => Object.values(this._featureFlagMap()) ?? []);

  private _featureFlagsFetched = new ReplaySubject<boolean>(1);
  public featureFlagsFetched$ = this._featureFlagsFetched.asObservable();

  /**
   * Indicated whether we have already fetched the latest feature flags from the
   * service or not. Usecase is the route guard we have, that must wait until we have
   * the latest data to make a decision.
   */
  public upToDate = this._upToDate.asObservable();

  constructor(private featureFlagRestService: FeatureFlagRestService) {
    Object.entries(environment.featureFlags).forEach(([name, enabled]) => {
      this._featureFlagMap.update(map => {
        map[name] = {name, enabled, description: '', diff: true};
        return map;
      });
    });

    this.featureFlagRestService
      .getFeatureFlags()
      .pipe(
        tap(this.updateDynamicFeatureFlags),
        tap(() => {
          this._upToDate.next(true);
          this._featureFlagsFetched.next(true);
        }),
        catchError((err: unknown) => {
          this.consoleLoggingService.error(err);
          this._upToDate.next(false);
          this._featureFlagsFetched.next(true);
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public hasFeaturesEnabled(...featureKeys: FeatureFlagKey[]): Signal<boolean> {
    return computed(() => featureKeys.every(f => this._featureFlagMap()?.[f]?.enabled ?? false));
  }

  public toggleFeature(flagName: string): void {
    if (environment.production) {
      this.consoleLoggingService.error('Toggling features on production is disallowed!');
      return;
    }
    const updatedFlagMap = {...this._featureFlagMap()};
    if (!(flagName in updatedFlagMap)) return;

    updatedFlagMap[flagName].enabled = !updatedFlagMap[flagName].enabled;
    this._featureFlagMap.set(updatedFlagMap);
    this.snackbarService.openSnackbar({
      text: `The modified feature '${flagName}' will only have local effect and will last until the next reload.` as TranslationString,
      type: MessageType.Info
    });
  }

  private updateDynamicFeatureFlags = (featureFlags: FeatureFlagDto[]): void => {
    // log diffing keys
    const packagedFlags = Object.keys(environment.featureFlags);
    const diffKeys = xor(
      packagedFlags,
      featureFlags.map(f => f.name)
    );
    if (diffKeys.length > 0) {
      console.warn('Found feature flags that are not set:', diffKeys);
    }

    const updatedFlagMap = {...this._featureFlagMap()};
    featureFlags.forEach(f => {
      updatedFlagMap[f.name!] = {...f, diff: diffKeys.includes(f.name!)};
    });
    this._featureFlagMap.set(updatedFlagMap);
  };
}
