import {
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
  computed,
  effect,
  inject,
  input,
  untracked
} from '@angular/core';
import {FeatureFlagKey} from '../config/feature-flags';
import {FeatureFlagService} from '../services/feature-flag/feature-flag.service';

@Directive({selector: '[appFeatureFlag]', standalone: true})
export class FeatureFlagDirective<T> {
  private featureFlagService = inject(FeatureFlagService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  public appFeatureFlag = input.required<FeatureFlagKey>();
  public appFeatureFlagElse = input<TemplateRef<T>>();

  private hasFeatures = computed(() => this.featureFlagService.hasFeaturesEnabled(this.appFeatureFlag())());

  constructor(
    private templateRef: TemplateRef<T>,
    private viewContainer: ViewContainerRef
  ) {
    effect(
      () => {
        const hasFeatures = this.hasFeatures();
        const elseTemplateRef = this.appFeatureFlagElse();

        // IMPORTANT: This needs to stay outside of the reactive context!
        untracked(() => {
          this.updateView(hasFeatures, this.templateRef, elseTemplateRef);
        });
      },
      {allowSignalWrites: true}
    );
  }

  private updateView(featuresEnabled: boolean, ifTemplateRef: TemplateRef<T>, elseTemplateRef?: TemplateRef<T>) {
    this.viewContainer.clear();
    if (featuresEnabled) {
      this.viewContainer.createEmbeddedView(ifTemplateRef);
    } else if (!featuresEnabled && elseTemplateRef) {
      this.viewContainer.createEmbeddedView(elseTemplateRef);
    }

    //FIXME: can be removed after upgrade to Angular v17.3.x
    this.changeDetectorRef.detectChanges();
  }
}
