/* eslint-disable no-console */
import {Injectable} from '@angular/core';
import {TranslationParams} from '@shared/shared-module/utils/translation.utils';
import {LoggingService} from './base-logging.service';

/**
 * This service only logs to console and does not call the
 * logging API endpoints. This is especially useful for unit testing,
 * where console.error can imply a real error in the test.
 */
@Injectable({providedIn: 'root'})
export class ConsoleLoggingService extends LoggingService {
  /**
   * We should not use console.error directly in our application, as then we cannot
   * distinguish during testing if the error has been thrown by the test.
   * Now every direct console.error (called if the test has an issue), will
   * lead to a test fail.
   * @param message
   * @param optionalParams
   */
  public error(message?: string | unknown, ...optionalParams: TranslationParams): void {
    console.error(message, ...optionalParams);
  }
}
