/**
 * MSA Person Data API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum CommunicationLanguageType {
    Fra = 'FRA',
    Ger = 'GER',
    Ita = 'ITA'
}

