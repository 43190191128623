import {ChangeDetectorRef, inject, Pipe, PipeTransform} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs';

export interface LocalizedObject {
  de?: string;
  fr?: string;
  it?: string;
}
@Pipe({
  name: 'translateObject',
  pure: false, // needs to be re-evaluated on language change,
  standalone: true
})
export class TranslateObjectPipe implements PipeTransform {
  private translateService = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    // listen to language changes and update translated text
    this.translateService.onLangChange
      .pipe(
        tap(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  transform(object: LocalizedObject | undefined): string {
    const currentLang = this.translateService.currentLang;
    return translateObject(object, currentLang);
  }
}

export function translateObject(object: LocalizedObject | undefined, lang: string): string {
  if (!object) return '';

  return object[lang.toLowerCase() as keyof LocalizedObject] ?? '';
}
