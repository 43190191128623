import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MessageType, MessageTypeConst} from '../enums/messageType';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, MatIconModule],
  selector: 'app-msa-content-notice',
  standalone: true,
  templateUrl: './msa-content-notice.component.html'
})
export class MsaContentNoticeComponent {
  contentNoticeTitle = input<string>();
  footNoteBody = input<string>();
  type = input<MessageType | keyof typeof MessageTypeConst>(MessageType.Info);
  isDismissible = input(false);
  dismissed = false;

  public icon = computed(() => this.getIconName(this.type()));
  public bgColor = computed(() => this.getBgColor(this.type()));

  private getIconName(type: MessageType | keyof typeof MessageTypeConst) {
    switch (type) {
      case 'Info':
      case MessageType.Info:
        return 'circle_info';
      case 'Warning':
      case MessageType.Warning:
        return 'circle_warning';
      case 'Error':
      case MessageType.Error:
        return 'circle_not_ok';
      case 'Success':
      case MessageType.Success:
        return 'circle_check';
      default:
        return 'circle_info';
    }
  }

  private getBgColor(type: MessageType | keyof typeof MessageTypeConst) {
    switch (type) {
      case 'Info':
      case MessageType.Info:
        return 'tw-bg-blue';
      case 'Warning':
      case MessageType.Warning:
        return 'tw-bg-tuscany';
      case 'Error':
      case MessageType.Error:
        return 'tw-bg-vermillion';
      case 'Success':
      case MessageType.Success:
        return 'tw-bg-olive';
      default:
        return 'tw-bg-blue';
    }
  }

  dismiss() {
    this.dismissed = true;
  }
}
