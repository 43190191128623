/**
 * MSA Person Data API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageSkillsDto } from './languageSkillsDto.model';
import { BankAccountViewDto } from './bankAccountViewDto.model';
import { CodeDto } from './codeDto.model';
import { EmailDto } from './emailDto.model';
import { EmergencyContactDto } from './emergencyContactDto.model';
import { PhoneDto } from './phoneDto.model';
import { CommunicationLanguageType } from './communicationLanguageType.model';


export interface PersonCivilDataDto { 
    ahvNumber?: string;
    firstName?: string;
    lastName?: string;
    careOf?: string;
    street?: string;
    addressSuffix?: string;
    zipCode?: string;
    city?: string;
    occupationCode?: CodeDto;
    birthdate?: string;
    communicationLanguage?: CommunicationLanguageType;
    gender?: PersonCivilDataDtoGenderEnum;
    homeTown?: string;
    bankAccount?: BankAccountViewDto;
    emails?: Array<EmailDto>;
    phones?: Array<PhoneDto>;
    emergencyContacts?: Array<EmergencyContactDto>;
    languageSkills?: Array<LanguageSkillsDto>;
}
export enum PersonCivilDataDtoGenderEnum {
    M = 'M',
    F = 'F'
};



