/**
 * MSA Person Data API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum VerificationStatusDto {
    Verified = 'VERIFIED',
    NotVerified = 'NOT_VERIFIED',
    NonePreferred = 'NONE_PREFERRED'
}

