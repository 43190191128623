import {DestroyRef, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AbstractControl, Validators} from '@angular/forms';
import {distinctUntilChanged, map, tap} from 'rxjs';

/**
 * Since we're not using material UI components, we cannot use the included label for form fields.
 * This means that we have a separate <mat-label> tag, that doesn´t know if the field is required.
 * This directive can be directly applied on <mat-label> tags, given a certain form field. This
 * directive then will check if the input is required and adds the ' *' character at the end.
 */
@Directive({
  selector: '[appMarkRequired]',
  standalone: true
})
export class MarkRequiredDirective implements OnInit {
  @Input('appMarkRequired') formControl: AbstractControl | null = null;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    if (!this.formControl) return;

    // Assumption: Upon changing the validators, it is mandatory to call `updateValueAndValidity()`.
    // Otherwise the labels won´t update.
    this.formControl.statusChanges
      .pipe(
        map(() => this.isFormRequired()),
        distinctUntilChanged(), // only do something on value change
        tap(isRequired => this.addOrRemoveAsterisk(isRequired)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    // to set intial label correctly
    this.addOrRemoveAsterisk(this.isFormRequired());
  }

  private isFormRequired(): boolean {
    return this.formControl?.hasValidator(Validators.required) ?? false;
  }

  private addOrRemoveAsterisk(isRequired: boolean): void {
    if (isRequired) {
      this.renderer.addClass(this.elementRef.nativeElement, 'required');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'required');
    }
  }
}
